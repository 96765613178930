html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Overpass', 'sans serif';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  font-family: 'Overpass', 'sans serif';
}

/* Hide arrows for number type inputs */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
}

.hero-content {
       position: relative;
       top:150px !important;
       padding: 40px !important;
       background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 44.81%, rgba(0, 0, 0, 0) 100%);
}

@media(max-width:768px) {
       .hero-content {
              top: 0px !important;
       }
}
